import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  createTheme,
  ThemeProvider,
  InputAdornment, IconButton
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { userCreateSchema } from "./validation";
import ecoles from "../components/json/ecoles.json";
import campuses from "../components/json/campus.json"

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#d32f2f"
        }
      }
    }
  }
});

function CreateUser() {
  const methods = useForm({
    resolver: yupResolver(userCreateSchema),
  });
  const { errors, handleSubmit, register, control, watch, setValue } = methods;
  const navigate = useNavigate();
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const visibility = watch("Visibility__c");
  const division = watch("School__c");
  useEffect(() => {
    setValue('UserDivision__c', division);
  }, [division, setValue]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      const userData = {
        ...data,
      }
      if (data.Visibility__c === 'Nationale') {
        userData.Campus__c = null;
      }
      const response = await axios.post(`${SERVER_URL}/events/createSalonUser`, {
        user: userData
      });
      if (response.data.user.success) {
        setMessage("L'utilisateur a été créé avec succès");
        setTimeout(() => {
          navigate("/UserList");
        }, 3000);
      } else {
        setMessage(response.data.error || response.data.message);
      }
    } catch (err) {
      console.error(err);
      setMessage(
        "Une est survenue lors de la création de l'utilisateur. Veuillez réessayer."
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <FormProvider {...methods}>
        <Paper
          variant="outlined"
          style={{
            position: "absolute",
            top: "calc(50% + 100px)",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
          }}
        >
          <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Création d'un utilisateur</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5}>
              <Grid item xs={12}>

                <TextField
                  name="FirstName__c"
                  label="Prénom"
                  {...register("FirstName__c")}
                  required
                  error={!!errors?.FirstName__c}
                  helperText={errors?.FirstName__c?.message}
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  name="LastName__c"
                  label="Nom"
                  required
                  {...register("LastName__c")}
                  error={!!errors?.LastName__c}
                  helperText={errors?.LastName__c?.message}
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  name="Email__c"
                  label="Email"
                  required
                  type="email"
                  {...register("Email__c")}
                  error={!!errors?.Email__c}
                  helperText={errors?.Email__c?.message}
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <TextField
                  name="Password__c"
                  label="Mot de passe"
                  required
                  type={showPassword ? "text" : "password"}
                  {...register("Password__c")}
                  error={!!errors?.Password__c}
                  helperText={errors?.Password__c?.message}
                  fullWidth
                  style={{ marginBottom: "20px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  style={{ marginBottom: "20px" }}
                >
                  <InputLabel id="school-label">Ecole</InputLabel>
                  <Controller
                    name="School__c"
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Ecole"
                        error={Boolean(errors?.School__c)}
                      >
                        {ecoles.map((ecole, i) => (
                          <MenuItem key={i} value={ecole}>
                            {ecole}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                  style={{ marginBottom: "20px" }}
                >
                  <InputLabel id="visibility-label">Visibilité</InputLabel>
                  <Controller
                    name="Visibility__c"
                    control={control}
                    defaultValue=''
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Visibilité"
                        error={Boolean(errors?.Visibility__c)}
                      >
                        <MenuItem value="Nationale">Nationale</MenuItem>
                        <MenuItem value="Campus">Campus</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {visibility !== 'Nationale' && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    required
                    style={{ marginBottom: "20px" }}
                  >
                    <InputLabel id="campus-label">Campus</InputLabel>
                    <Controller
                      name="Campus__c"
                      control={control}
                      defaultValue=''
                      render={({ field }) => (
                        <Select
                          {...field}
                          label="Campus"
                          error={Boolean(errors?.Campus__c)}
                        >
                          {campuses.map((campus, i) => (
                            <MenuItem key={i} value={campus}>
                              {campus}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                )}
                <Controller
                  name="UserDivision__c"
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Division"
                      error={Boolean(errors?.UserDivision__c)}
                      helperText={errors?.UserDivision__c?.message}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <Button
                  type="submit" variant="contained" color="primary">
                  Créer l'utilisateur
                </Button>
              </Grid>
            </Grid>
          </form>
          {message && <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>{message}</p>}
        </Paper>
      </FormProvider>
    </ThemeProvider>
  );
}

export default CreateUser;


