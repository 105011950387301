import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  Paper,
  ThemeProvider,
  createTheme,
  InputAdornment,
  IconButton,
  Box,
  CircularProgress
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import loginSchema from "./validation";
import UserContext from "./userContext";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#d32f2f"
        }
      }
    }
  }
});

function LoginForm() {
  const methods = useForm({
    resolver: yupResolver(loginSchema),
  });
  const { errors, handleSubmit, register } = methods;
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const [loginStatus, setLoginStatus] = useState("");
  const [id, setId] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstLogin, setFirstLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const context = useContext(UserContext);
  const setUser = context ? context.setUser : null;
  const { setIsLoggedIn, setRole } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [new_sk, setNew_sk] = useState(null);

  useEffect(() => {
    axios.get(`${SERVER_URL}/events/key`, { withCredentials: true })
      .then((response) => {
        setNew_sk(response.data.key);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      }
      )
  }, [SERVER_URL]);

  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

const onSubmit = async (data) => {
    const userData = {
      Email__c: data.Email__c,
      Password__c: data.Password__c,
    };
    setOldPassword(data.Password__c);
    try {
      const response = await axios.post(`${SERVER_URL}/events/login`, userData, {
        withCredentials: true,
      });
      
      if (response.data.success) {
        localStorage.setItem('jwtToken', response.data.token);
        setId(response.data.id);
        setRole(response.data.role);
        setUser(response.data);
        setIsLoggedIn(true);
        if (new_sk) {
          setLoading(false);
          const user = response.data;
          const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(user),
            new_sk
          ).toString();
          Cookies.set(
            "user",
            ciphertext,
            { secure: true, sameSite: "strict" },
            { expires: 1 }
          );
        }
        else {
          setLoading(true);
        }
        if (response.data.firstLogin) {
          setFirstLogin(true);
        } else {
          setLoginStatus("Vous êtes connecté(e)!");
          navigate("/SalonsList");
        }
      } else {
        setLoginStatus(response.data.error || response.data.message);
      }
    } catch (err) {
      setLoginStatus("Vérifiez votre email et/ou votre mot de passe");
      console.error(loginStatus, err);
    }
  };

  const handleChangePassword = async () => {
    const userData = {
      Id: id,
      Email__c: methods.getValues("Email__c"),
      newPassword: newPassword,
      oldPassword: oldPassword,
    };
    if (newPassword !== confirmPassword) {
      console.log("Les mots de passe ne correspondent pas");
      return;
    }
    try {
      const response = await axios.post(
        `${SERVER_URL}/events/changePassword`,
        userData
      );
      if (response.data.success) {
        console.log("Mot de passe modifié avec succès.");
  
        const loginData = {
          Email__c: userData.Email__c,
          Password__c: userData.newPassword,
        };
        const loginResponse = await axios.post(`${SERVER_URL}/events/login`, loginData, { withCredentials: true },);
        if (loginResponse.data.success) {
          localStorage.setItem('jwtToken', loginResponse.data.token);

          setId(loginResponse.data.id);
          setRole(loginResponse.data.role);
          setUser(loginResponse.data);
          setIsLoggedIn(true);
          const user = response.data;
          const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(user),
            new_sk
          ).toString();
          Cookies.set(
            "user",
            ciphertext,
            { secure: true, sameSite: "strict" },
            { expires: 1 }
          );
          console.log("Connexion réussie après le changement de mot de passe.");
          navigate("/SalonsList");
        } else {
          console.log("La connexion a échoué après le changement de mot de passe.");
        }
      } else {
        console.log("Le mot de passe n'a pas pu être mis à jour");
      }
    } catch (err) {
      console.error(err);
    }
  };
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <FormProvider {...methods}>
        <Paper
          variant="outlined"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
          }}
        >
          <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Connexion</h1>
          {firstLogin ? (
            <form onSubmit={handleSubmit(handleChangePassword)}>
              <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Ceci est votre première connexion, veuillez vous créer un mot de passe.</p>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="NewPassword"
                    type={showPassword ? "text" : "password"}
                    label="Nouveau mot de passe"
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="ConfirmPassword"
                    type={showPassword ? "text" : "password"}
                    label="Confirmer le mot de passe"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit"
                    sx={{
                      backgroundColor: "#3f51b5",
                      color: "white",
                      '&:hover': {
                        backgroundColor: "#2c387e",
                      }
                    }}
                  >Modifier le mot de passe</Button>
                </Grid>
              </Grid>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="Email__c"
                    type="email"
                    label="Email"
                    {...register("Email__c")}
                    error={!!errors?.Email__c}
                    helperText={errors?.Email__c?.message}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="Password__c"
                    type={showPassword ? "text" : "password"}
                    label="Mot de passe"
                    {...register("Password__c")}
                    error={!!errors?.Password__c}
                    helperText={errors?.Password__c?.message}
                    fullWidth
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Connexion
                  </Button>
                  <Button variant="outlined" style={{ marginLeft: '10px' }} onClick={() => navigate("/ForgotPassword")}>
                    Mot de passe oublié
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
          <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>{loginStatus}</p>
        </Paper>
      </FormProvider>
    </ThemeProvider>
  );
}

export default LoginForm;
