import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  createTheme,
  ThemeProvider,
  Paper,
  Box,
  Grid,
  CircularProgress
} from "@mui/material";
import ecoles from "../components/json/ecoles.json";
import campuses from "../components/json/campus.json";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
  },
});

function UserUpdate() {
  const { id } = useParams();
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const methods = useForm();
  const { errors, control } = methods;

  const [user, setUser] = useState(null);
  const [updateStatus, setUpdateStatus] = useState("");
  const visibility = user?.Visibility__c;
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${SERVER_URL}/events/getSingleUser`, { params: { userId: id } })
      .then((response) => {
        setUser(response.data.data[0]);
      })
      .catch((error) => {
        console.error("Une erreur est survenue:", error);
      });
  }, [id, SERVER_URL]);

  const handleUpdate = async () => {
    try {
      const userUpdate = {
        ...user,
        Id: id,
        UserDivision__c: user.School__c,
      };
      console.log("user update", userUpdate);
      if (user.Visibility__c === "Nationale") {
        userUpdate.Campus__c = null;
      }
      const response = await axios.patch(`${SERVER_URL}/events/updateUser/`, userUpdate);

      if (response.data.success) {
        setUpdateStatus("Utilisateur mis à jour avec succès !");
        setTimeout(() => {
          navigate("/UserList");
        }, 3000);
      } else {
        console.error(
          "Une erreur est survenue lors de la mise à jour de l'utilisateur."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper
        variant="outlined"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          padding: "20px",
        }}
      >
        <h1 style={{ fontFamily: "Arial, sans-serif", fontWeight: "light" }}>
          Mise à jour de l'utilisateur
        </h1>
        <div>
          <div>
            <div>
              <TextField
                label="Prénom"
                value={user.FirstName__c}
                onChange={(e) =>
                  setUser({ ...user, FirstName__c: e.target.value })
                }
                variant="outlined"
                margin="normal"
                focused
                fullWidth
              />
            </div>
            <div>
              <TextField
                label="Nom"
                value={user.LastName__c}
                onChange={(e) =>
                  setUser({ ...user, LastName__c: e.target.value })
                }
                variant="outlined"
                margin="normal"
                focused
                fullWidth
              />
            </div>
            <div>
              <TextField
                label="Email"
                value={user.Email__c}
                onChange={(e) => setUser({ ...user, Email__c: e.target.value })}
                variant="outlined"
                margin="normal"
                focused
                fullWidth
                style={{ marginBottom: "20px" }}
              />
            </div>

            <div>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "20px" }}
              >
                <InputLabel id="school-label">Ecole</InputLabel>
                <Select
                  label="Ecole"
                  value={user.School__c}
                  onChange={(e) =>
                    setUser({ ...user, School__c: e.target.value })
                  }
                  variant="outlined"
                >
                  {ecoles.map((ecole, i) => (
                    <MenuItem key={i} value={ecole}>
                      {ecole}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginBottom: "20px" }}
              >
                <InputLabel id="visibility-label">Visibilité</InputLabel>
                <Select
                  label="Visibilité"
                  value={user.Visibility__c}
                  onChange={(e) =>
                    setUser({ ...user, Visibility__c: e.target.value })
                  }
                  variant="outlined"
                >
                  <MenuItem value="Nationale">Nationale</MenuItem>
                  <MenuItem value="Campus">Campus</MenuItem>
                </Select>
              </FormControl>
              {visibility !== "Nationale" && (
                <FormControl
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                >
                  <InputLabel id="campus-label">Campus</InputLabel>
                  <Controller
                    name="Campus__c"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Campus"
                        error={Boolean(errors?.Campus__c)}
                      >
                        {campuses.map((campus, i) => (
                          <MenuItem key={i} value={campus}>
                            {campus}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              )}
            </div>

            {/* Boutons avec espacement et centrage */}
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={5}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleUpdate}
                >
                  Mettre à jour
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  component={Link}
                  to="/UserList"
                >
                  Annuler
                </Button>
              </Grid>
            </Grid>

            <p style={{ fontFamily: "Arial, sans-serif", fontWeight: "light" }}>
              {updateStatus}
            </p>
          </div>
        </div>
      </Paper>
    </ThemeProvider>
  );
}

export default UserUpdate;
