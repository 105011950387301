import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Grid, TextField, Button, Paper, InputAdornment, IconButton }
    from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';


function PasswordUpdate() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
    const loginNavigation = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleUpdatePassword = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            alert('Les mots de passe ne correspondent pas');
            return;
        }
        try {
            const resetToken = window.location.pathname.split('/')[2];
            const response = await axios.post(`${SERVER_URL}/events/updatePassword`, {
                resetToken,
                newPassword,
            });
            if (response.data) {
                alert('Votre mot de passe a été mis à jour');
                loginNavigation('/login');
            } else {
                alert('Une erreur est survenue');
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Paper
            variant="outlined"
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
            }}
        >
            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Veuillez saisir un nouveau mot de passe</h1>
            <form onSubmit={handleUpdatePassword}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="New Password"
                            type={showPassword ? "text" : "password"}
                            label="Nouveau mot de passe"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="ConfirmPassword"
                            type={showPassword ? "text" : "password"}
                            label="Confirmer le mot de passe"
                            value={confirmPassword}
                            fullWidth
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit"
                            sx={{
                                backgroundColor: "#3f51b5",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "#1a237e",
                                }
                            }}
                        >Mettre à jour le mot de passe</Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
}

export default PasswordUpdate;