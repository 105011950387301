import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button, Paper, Grid } from '@mui/material';

function ForgotPassword() {
    const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleResetPassword = async (event) => {
        event.preventDefault();
        const response = await axios.post(`${SERVER_URL}/events/resetPassword`, { userEmail: email });
        if (response.data) {
            setMessage('Un mail de réinitialisation vous a été envoyé');
            setTimeout(() => {
                navigate('/login');
            }, 3000);
        } else {
            setMessage('Une erreur est survenue');
        }
    };

    return (
        <Paper
            variant="outlined"
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
            }}
        >
            <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Réinitialisation du mot de passe</h1>
            <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}> Vous avez fait une demande de réinitialisation de mot de passe. </p>
            <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Entrez votre adresse mail pour recevoir un lien de réinitialisation</p>
            <form onSubmit={handleResetPassword}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            label="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit"
                            sx={{
                                backgroundColor: "#3f51b5",
                                color: "white",
                                '&:hover': {
                                    backgroundColor: "#1a237e",
                                }
                            }}
                        >Réinitialiser le mot de passe</Button>
                    </Grid>
                </Grid>
            </form>
            <p style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>{message}</p>
        </Paper>
    );
}

export default ForgotPassword;