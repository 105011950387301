import React, { createContext, useContext, useState } from 'react';

const SalonDataContext = createContext({
        salonData: null, 
        setSalonData: () => {}, 
});

export const useSalonData = () => useContext(SalonDataContext);

export const SalonDataProvider = ({ children }) => {
    const [salonData, setSalonData] = useState(null);

    return (
        <SalonDataContext.Provider value={{ salonData, setSalonData }}>
            {children}
        </SalonDataContext.Provider>
    );
};

export default SalonDataContext;