import React, { useState, useEffect, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Grid,
  Typography,
  Radio,
  createTheme,
  ThemeProvider,
  Box,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { salonUpdateSchema } from "./validation";
import { DeadlineContext } from "../App"


const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
  },
});



const UpdateFicheSalon = () => {

  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [message, setMessage] = useState("");
  const { id: salonId } = useParams();
  const [salonData, setSalonData] = useState({});
  const [loading, setLoading] = useState(false);
  const deadline = new Date(useContext(DeadlineContext));

  const section = localStorage.getItem('section');


  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(salonUpdateSchema[section]),
  });


  useEffect(() => {
    const fetchSalon = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${SERVER_URL}/events/getFicheSalon`, {
          params: {
            salonId: salonId,
          },
        });
        if (response.data.success) {
          setSalonData(response.data.data[0]);
          reset({
            ProspectsMetN__c: response.data.data[0].ProspectsMetN__c,
            PotentielCandidateN__c: response.data.data[0].PotentielCandidateN__c,
            DCN__c: response.data.data[0].DCN__c,
            RegisteredN__c: response.data.data[0].RegisteredN__c,
            LocationQuality__c: response.data.data[0].LocationQuality__c,
            LeadQuality__c: response.data.data[0].LeadQuality__c,
            TrafficQuality__c: response.data.data[0].TrafficQuality__c,
            QualityOfTheReception__c: response.data.data[0].QualityOfTheReception__c,
            Improvments__c: response.data.data[0].Improvments__c,
            DesiredArea__c: response.data.data[0].DesiredArea__c,
            Observations__c: response.data.data[0].Observations__c,
            DesiredLocation__c: response.data.data[0].DesiredLocation__c,
            ToRedo__c: response.data.data[0].ToRedo__c,
          })
        } else {
          console.error("Failed to fetch salon details:", response.data.error);
        }
      } catch (err) {
        console.error("An error occurred:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchSalon();
  }, [salonId, SERVER_URL, setSalonData, reset]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const salonName = salonData.Name;
  const onSubmit = async (data) => {
    try {
      const response = await axios.patch(`${SERVER_URL}/events/updateFicheSalon`, {
        salonId: salonId,
        data: data,
      });
      if (response.data.success) {
        console.log("La fiche du salon a été mise à jour avec succès");
        navigate(`/FicheSalon/${salonId}`);
      } else {
        setMessage(response.data.error || response.data.message);
      }
    } catch (error) {
      console.error("Error in onSubmit:", error);
      setMessage(
        "Une erreur est survenue lors de la mise à jour de la fiche salon. Veuillez réessayer."
      );
    }
  };
  const currentDate = new Date();

  let updateMessage = '';
  if (currentDate < deadline) {
    updateMessage = `Vous pourrez modifier les données des ce salons jusqu'au ${deadline.toLocaleDateString()}`;
  } else if (currentDate.toDateString() === new Date(deadline).toDateString()) {
    updateMessage = "C'est le dernier jour pour modifier les données des salons";
  } else if (currentDate > deadline) {
    updateMessage = `Les données des salons ne sont plus modifiables depuis le ${deadline.toLocaleDateString()}`;
  }

  return (
    <ThemeProvider theme={theme}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ height: "100%", width: "95%", margin: '100px auto' }}>
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{ marginTop: 8, paddingBottom: 8 }}
          >
            <Grid item>
              <h1 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Modification du salon {salonName}</h1>
            </Grid>
            <Grid item>
              <p style={{ fontFamily: 'Arial, sans-serif', color: '#c70912', fontSize: '20px' }}>{updateMessage}</p>
            </Grid>

            {section === "quantitatif" && (
              <>
                <Grid item id="sectionQuanti">
                  <h2 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Données quantitatives du salon</h2>
                </Grid>
                <Grid item>
                  <Controller
                    name="ProspectsMetN__c"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.ProspectsMetN__c}
                        helperText={errors?.ProspectsMetN__c?.message}
                        label="Prospects rencontrés:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="PotentielCandidateN__c"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.PotentielCandidateN__c}
                        helperText={errors?.PotentielCandidateN__c?.message}
                        label="Candidats potentiels n:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="DCN__c"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.DCN__c}
                        helperText={errors?.DCN__c?.message}

                        label="Candidats n:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="RegisteredN__c"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.RegisteredN__c}
                        helperText={errors?.RegisteredN__c?.message}

                        label="Inscrits n:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {section === "qualitatif" && (
              <>
                <Grid item id="sectionQuali">
                  <h2 style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'light' }}>Données qualitatives du salon</h2>
                </Grid>
                <Grid item>
                  <Controller
                    name="LocationQuality__c"
                    control={control}
                    rules={{ required: true, min: 0, max: 10 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        error={!!errors.LocationQuality__c}
                        helperText={errors?.LocationQuality__c?.message}

                        label="Qualité de l'emplacement:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="LeadQuality__c"
                    control={control}
                    rules={{ required: true, min: 0, max: 10 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        error={!!errors.LeadQuality__c}
                        helperText={errors?.LeadQuality__c?.message}

                        label="Qualité des prospects:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="TrafficQuality__c"
                    control={control}
                    rules={{ required: true, min: 0, max: 10 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        error={!!errors.TrafficQuality__c}
                        helperText={errors?.TrafficQuality__c?.message}

                        label="Qualité du traffic:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="QualityOfTheReception__c"
                    control={control}
                    rules={{ required: true, min: 0, max: 10 }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        error={!!errors.QualityOfTheReception__c}
                        helperText={errors?.QualityOfTheReception__c?.message}

                        label="Qualité de l'organisation:"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="Observations__c"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Observations:"
                        multiline
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="Improvments__c"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Axes d'amélioration:"
                        multiline
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box ml={2}>
                      {" "}
                      <Typography> A refaire ? </Typography>
                    </Box>{" "}
                  </Grid>
                  <Grid item>
                    <Controller
                      name="ToRedo__c"
                      control={control}
                      //defaultValue="Non"
                      render={({ field }) => (
                        <RadioGroup {...field} row>
                          <FormControlLabel
                            value="Oui"
                            control={<Radio />}
                            label="Oui"
                            style={{ marginLeft: 10 }}

                          />
                          <FormControlLabel
                            value="Non"
                            control={<Radio />}
                            label="Non"
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
                {watch("ToRedo__c") === "Oui" && (
                  <>
                    <Grid item>
                      <Controller
                        name="DesiredArea__c"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.DesiredArea__c}
                            helperText={errors?.DesiredArea__c?.message}

                            label="Superificie souhaitée l'an prochain:"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    <Grid item>
                      <Controller
                        name="DesiredLocation__c"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Emplacement souhaité l'an prochain:"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            {currentDate <= deadline && (
              <>
                <Grid item xs={6}>
                  <Button type="submit" variant="contained" fullWidth>
                    Enregistrer
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="reset"
                    variant="outlined"
                    component={Link}
                    to={`/FicheSalon/${salonId}`}
                    fullWidth
                  >
                    Annuler
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </form>
    </ThemeProvider>
  );
};

export default UpdateFicheSalon;


