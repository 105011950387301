import React, { useEffect, useState, useContext } from "react";
import { Grid, Box, CircularProgress, Typography } from "@mui/material";
import { DataGrid, GridColumnMenu } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSalonData } from "./salonDataContext";
import { DeadlineContext } from "../App"

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: "#E3E3E3",
          fontWeight: "500",
          fontSize: "15px",
          color: "black",
        },
      },
    },
  },
});

const FicheSalon = () => {
  const SERVER_URL = process.env.REACT_APP_SFCONNECT_URL;
  const { id: salonId } = useParams();
  const [salon, setSalon] = useState(null);
  const { control } = useForm();
  const { setSalonData } = useSalonData();
  const deadline = new Date(useContext(DeadlineContext));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalon = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${SERVER_URL}/events/getFicheSalon`, {
          params: {
            salonId: salonId,
          },
        });
        if (response.data.success) {
          setSalon(response.data.data[0]);
          setSalonData(response.data.data[0]);
        } else {
          console.error("Failed to fetch salon details:", response.data.error);
        }
      } catch (err) {
        console.error("An error occurred:", err);
      } finally {
        setLoading(false);
      }
    };
    fetchSalon();
  }, [salonId, SERVER_URL, setSalonData]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }


  const rowData = [
    {
      id: 1,
      label: "Date de début",
      value: new Date(salon.StartDate).toLocaleDateString(),
      editable: false,
    },
    {
      id: 2,
      label: "Date de fin",
      value: new Date(salon.EndDate).toLocaleDateString(),
      editable: false,
    },
    {
      id: 3,
      label: "Organisateur",
      value: salon.Organizer__r?.Name,
      editable: false,
    },
    { id: 4, label: "Ville", value: salon.City__c, editable: false },
    {
      id: 5,
      label: "Superficie louée",
      value: salon.RentedArea__c,
      editable: false,
    },
    {
      id: 6,
      label: "N° de stand",
      value: salon.StandNumber__c,
      editable: false,
    },
  ];

  const third = Math.ceil(rowData.length / 3);
  const firstthird = rowData.slice(0, third);
  const secondthird = rowData.slice(third, 2 * third);
  const thirdthird = rowData.slice(2 * third, rowData.length);

  //faire 3 rows pour les 3 colonnes

  const standData = [
    {
      field: "label",
      headerName: " Stand",
      headerClassName: "app-theme",
      headerAlign: 'right',
      width: 300,
    },
    {
      field: "value",
      headerName: " ",
      headerClassName: "app-theme",
      width: 300,
      editable: false,
    },
  ];
  const dateData = [
    {
      field: "label",
      headerName: "Dates",
      headerClassName: "app-theme",
      headerAlign: 'right',
      width: 300,
    },
    {
      field: "value",
      headerName: " ",
      headerClassName: "app-theme",
      width: 300,
      editable: false,
    },
  ];
  const organizerData = [
    {
      field: "label",
      headerName: " Ville et organisateur",
      headerClassName: "app-theme",
      headerAlign: 'right',
      width: 300,
    },
    {
      field: "value",
      headerName: " ",
      headerClassName: "app-theme",
      width: 300,
      editable: false,
    },
  ];
  const columnQuanti = [
    {
      field: "label",
      headerName: " Bilan quantitatif",
      headerClassName: "app-theme",
      headerAlign: 'right',
      width: 300,
    },
    {
      field: "value",
      headerName: " ",
      headerClassName: "app-theme",
      align: 'right',
      width: 300,
      editable: false,
    },
  ];

  const columQuali = [
    {
      field: "label",
      headerName: " Bilan qualitatif",
      headerClassName: "app-theme",
      headerAlign: 'right',
      width: 300,

    },
    {
      field: "value",
      headerName: " ",
      headerClassName: "app-theme",
      align: 'right',
      width: 300,
      editable: false,
    },
  ]

  const rowQuanti = [
    {
      id: 1,
      label: "Prospects rencontrés n",
      value: salon.ProspectsMetN__c,
    },
    {
      id: 2,
      label: "Prospects rencontrés n-1",
      value: salon.ProspectsMetN1__c,
    },
    {
      id: 3,
      label: "Prospects rencontrés n-2",
      value: salon.ProspectsMetN2__c,
    },
    {
      id: 4,
      label: "Candidats n ",
      value: salon.DCN__c,
    },
    {
      id: 5,
      label: "Candidats potentiels ",
      value: salon.PotentielCandidateN__c,
    },
    {
      id: 6,
      label: "Candidats potentiels n-1",
      value: salon.PotentialCandidateN1__c,
    },
    {
      id: 7,
      label: "Candidats potentiels n-2",
      value: salon.PotentialCandidateN2__c,
    },
    { id: 8, label: "Inscrits n", value: salon.RegisteredN__c },
    {
      id: 9,
      label: "Inscrits n-1",
      value: salon.RegisteredN1__c,
    },
    {
      id: 10,
      label: "Inscrits n-2",
      value: salon.RegisteredN2__c,
    },
  ];
  const rowQuali = [
    {
      id: 1,
      label: "Qualité de l'emplacement",
      value: salon.LocationQuality__c,
    },
    {
      id: 2,
      label: "Qualité des prospects",
      value: salon.LeadQuality__c,
    },
    {
      id: 3,
      label: "Qualité du trafic",
      value: salon.TrafficQuality__c,
    },
    {
      id: 4,
      label: "Qualité de l'organisation",
      value: salon.QualityOfTheReception__c,
    },
    {
      id: 5,
      label: "Observations",
      value: salon.Observations__c,
    },
    {
      id: 6,
      label: "Axes d'amélioration",
      value: salon.Improvments__c,
    },
    { id: 7, label: "A refaire", value: salon.ToRedo__c },
    {
      id: 8,
      label: "Superficie souhaitée l'an prochain",
      value: salon.DesiredArea__c,
    },
    {
      id: 9,
      label: "Emplacement souhaité l'an prochain",
      value: salon.DesiredLocation__c,
    },
  ];

  const currentDate = new Date();

  let updateMessage = '';
  if (currentDate < deadline) {
    updateMessage = `Vous pourrez modifier les données des ce salons jusqu'au ${deadline.toLocaleDateString()}.`;
  } else if (currentDate.toDateString() === new Date(deadline).toDateString()) {
    updateMessage = "C'est le dernier jour pour modifier les données des salons.";
  } else if (currentDate > deadline) {
    updateMessage = `Les données des salons ne sont plus modifiables depuis le ${deadline.toLocaleDateString()}.`;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ m: 10 }}>
        <Typography variant="h4" style={{ fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }} gutterBottom>
          {salon.Name}
        </Typography>
        <Grid item>
          <p style={{ fontFamily: 'Arial, sans-serif', color: '#c70912', fontSize: '20px' }}>{updateMessage}</p>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={4}>
            <Controller
              name="rowData1"
              control={control}
              defaultValue={firstthird}
              render={({ field }) => (
                <DataGrid
                  rows={field.value}
                  columns={dateData}
                  pageSize={5}
                  hideFooter
                  slots={{ columnMenu: CustomColumnMenu }}
                  sx={{ '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="rowData2"
              control={control}
              defaultValue={secondthird}
              render={({ field }) => (
                <DataGrid
                  rows={field.value}
                  columns={organizerData}
                  pageSize={5}
                  hideFooter
                  slots={{ columnMenu: CustomColumnMenu }}
                  sx={{ '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' } }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="rowData3"
              control={control}
              defaultValue={thirdthird}
              render={({ field }) => (
                <DataGrid
                  rows={field.value}
                  columns={standData}
                  pageSize={5}
                  hideFooter
                  slots={{ columnMenu: CustomColumnMenu }}
                  sx={{ '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { display: 'none' } }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box my={4}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Box height={400} mr={2}>
                <Controller
                  name="rowQuanti"
                  control={control}
                  defaultValue={rowQuanti}
                  render={({ field }) => (
                    <DataGrid
                      rows={field.value}
                      columns={columnQuanti}
                      pageSize={5}
                      hideFooter
                      slots={{ columnMenu: CustomColumnMenu }}
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box height={400}>
                <Controller
                  name="rowQuali"
                  control={control}
                  defaultValue={rowQuali}
                  render={({ field }) => (
                    <DataGrid
                      rows={field.value}
                      columns={columQuali}
                      pageSize={10}
                      hideFooter
                      slots={{ columnMenu: CustomColumnMenu }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Box my={3}>
            <Grid container justifyContent="space-around" alignItems="center">
              {currentDate <= deadline && (
                <Grid item >
                  <Link
                    to={{
                      pathname: `/UpdateFicheSalon/${salonId}`
                    }}
                    onClick={() => { }}
                  >
                    <Button
                      onClick={() => {
                        localStorage.setItem('section', 'quantitatif');
                      }}
                      sx={{
                        backgroundColor: "#3f51b5",
                        color: "white",
                        '&:hover': {
                          backgroundColor: "#2c387e",
                        }
                      }}>
                      Modifier
                    </Button>
                  </Link>
                </Grid>
              )}
              {currentDate <= deadline && (
                <Grid item>
                  <Link to={{ pathname: `/UpdateFicheSalon/${salonId}` }}>
                    <Button
                      onClick={() => {
                        localStorage.setItem('section', 'qualitatif');
                      }} sx={{
                        backgroundColor: "#3f51b5",
                        color: "white",
                        '&:hover': {
                          backgroundColor: "#2c387e",
                        }
                      }}>
                      Modifier
                    </Button>
                  </Link>
                </Grid>
              )}
            </Grid>
          </Box>
          <Box my={4} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FicheSalon;
